import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumberOrUndefined } from '@komo-tech/core/utils/number';

import { FieldTypes } from '@/common/models/form/FormFieldTypes';

import { AssetTypes } from '../AssetTypes';
import { FieldConfig, initTypedFieldConfig } from './FormFieldConfig';

export class FormFieldProperties {
  Code?: string;
  WordLimit?: number;
  FileLimit?: number;
  AllowedFileTypes?: string;
  placeholder?: string;
  HideLabel?: boolean;
  UserAccountForcePrompt?: boolean;
  AllowedCountry?: string;
  MinimumAge?: number;

  FieldConfigVersion?: string;
  FieldConfigJson?: string;

  IsReadOnly?: boolean;
  HtmlEditorStateJson?: string;

  //if we have a value by default, we want to hide the field in the form
  IsHiddenIfHasValue?: boolean;

  constructor(props?: Partial<FormFieldProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.WordLimit = asNumberOrUndefined(props.WordLimit);
    this.FileLimit = asNumberOrUndefined(props.FileLimit);
    this.MinimumAge = asNumberOrUndefined(props.MinimumAge);
    this.UserAccountForcePrompt = asBoolean(props.UserAccountForcePrompt);
    this.IsHiddenIfHasValue = asBoolean(props.IsHiddenIfHasValue);
    this.IsReadOnly = asBoolean(props.IsReadOnly);
  }

  getAllowedFileFields(): AssetTypes[] {
    if (!this.AllowedFileTypes) return [AssetTypes.Image];
    try {
      return JSON.parse(this.AllowedFileTypes);
    } catch {
      return [AssetTypes.Image];
    }
  }

  /**
   * Todo: migrate all field-specific config to FieldConfigs.
   * Currently only PhoneFieldConfig.AllowedCountries is pulled from here.
   */
  getTypedFieldConfig(fieldType?: FieldTypes): FieldConfig {
    if (!fieldType) {
      return undefined;
    }

    return initTypedFieldConfig(fieldType, this.FieldConfigJson);
  }
}
