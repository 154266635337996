import { FormFileAllowedType, getFileType } from '@komo-tech/core/utils/file';

export enum AssetTypes {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
  Pdf = 'Pdf'
}

export const getAssetTypeFromFile = (file: File) => {
  const type = getFileType(file);
  return toAssetType(type);
};

export const toFormFileAllowedType = (type: AssetTypes) => {
  let formFileAllowedType: FormFileAllowedType = undefined;
  switch (type) {
    case AssetTypes.Image:
      formFileAllowedType = 'image';
      break;
    case AssetTypes.Video:
      formFileAllowedType = 'video';
      break;
    case AssetTypes.Pdf:
      formFileAllowedType = 'pdf';
      break;
  }

  return formFileAllowedType;
};

export const toAssetType = (type: FormFileAllowedType) => {
  switch (type) {
    case 'image':
      return AssetTypes.Image;
    case 'pdf':
      return AssetTypes.Pdf;
    case 'video':
      return AssetTypes.Video;
    default:
      return AssetTypes.Unknown;
  }
};
