// Inspired by https://raw.githubusercontent.com/jackocnr/intl-tel-input/master/src/js/data.js

import { OptionModel } from '../models/OptionModel';

export interface CountryCode {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  areaCodes: string[];
}

export const AllCountries = (
  [
    ['United States', 'US', '1', 0, []],
    [
      'Canada',
      'CA',
      '1',
      1,
      [
        '204',
        '226',
        '236',
        '249',
        '250',
        '289',
        '306',
        '343',
        '365',
        '387',
        '403',
        '416',
        '418',
        '431',
        '437',
        '438',
        '450',
        '506',
        '514',
        '519',
        '548',
        '579',
        '581',
        '587',
        '604',
        '613',
        '639',
        '647',
        '672',
        '705',
        '709',
        '742',
        '778',
        '780',
        '782',
        '807',
        '819',
        '825',
        '867',
        '873',
        '902',
        '905'
      ]
    ],
    ['Afghanistan (‫افغانستان‬‎)', 'AF', '93', 0, []],
    ['Åland Islands', 'AX', '358', 1, ['18']],
    ['Albania (Shqipëri)', 'AL', '355', 0, []],
    ['Algeria (‫الجزائر‬‎)', 'DZ', '213', 0, []],
    ['American Samoa', 'AS', '1', 5, ['684']],
    ['Andorra', 'AD', '376', 0, []],
    ['Angola', 'AO', '244', 0, []],
    ['Anguilla', 'AI', '1', 6, ['264']],
    ['Antigua and Barbuda', 'AG', '1', 7, ['268']],
    ['Argentina', 'AR', '54', 0, []],
    ['Armenia (Հայաստան)', 'AM', '374', 0, []],
    ['Aruba', 'AW', '297', 0, []],
    ['Australia', 'AU', '61', 0, []],
    ['Austria (Österreich)', 'AT', '43', 0, []],
    ['Azerbaijan (Azərbaycan)', 'AZ', '994', 0, []],
    ['Bahamas', 'BS', '1', 8, ['242']],
    ['Bahrain (‫البحرين‬‎)', 'BH', '973', 0, []],
    ['Bangladesh (বাংলাদেশ)', 'BD', '880', 0, []],
    ['Barbados', 'BB', '1', 9, ['246']],
    ['Belarus (Беларусь)', 'BY', '375', 0, []],
    ['Belgium (België)', 'BE', '32', 0, []],
    ['Belize', 'BZ', '501', 0, []],
    ['Benin (Bénin)', 'BJ', '229', 0, []],
    ['Bermuda', 'BM', '1', 10, ['441']],
    ['Bhutan (འབྲུག)', 'BT', '975', 0, []],
    ['Bolivia', 'BO', '591', 0, []],
    ['Bosnia and Herzegovina (Босна и Херцеговина)', 'BA', '387', 0, []],
    ['Botswana', 'BW', '267', 0, []],
    ['Brazil (Brasil)', 'BR', '55', 0, []],
    ['British Indian Ocean Territory', 'IO', '246', 0, []],
    ['British Virgin Islands', 'VG', '1', 11, ['284']],
    ['Brunei', 'BN', '673', 0, []],
    ['Bulgaria (България)', 'BG', '359', 0, []],
    ['Burkina Faso', 'BF', '226', 0, []],
    ['Burundi (Uburundi)', 'BI', '257', 0, []],
    ['Cambodia (កម្ពុជា)', 'KH', '855', 0, []],
    ['Cameroon (Cameroun)', 'CM', '237', 0, []],
    ['Cape Verde (Kabu Verdi)', 'CV', '238', 0, []],
    ['Caribbean Netherlands', 'BQ', '599', 1, ['3', '4', '7']],
    ['Cayman Islands', 'KY', '1', 12, ['345']],
    [
      'Central African Republic (République centrafricaine)',
      'CF',
      '236',
      0,
      []
    ],
    ['Chad (Tchad)', 'TD', '235', 0, []],
    ['Chile', 'CL', '56', 0, []],
    ['China (中国)', 'CN', '86', 0, []],
    ['Christmas Island', 'CX', '61', 2, []],
    ['Cocos (Keeling) Islands', 'CC', '61', 1, []],
    ['Colombia', 'CO', '57', 0, []],
    ['Comoros (‫جزر القمر‬‎)', 'KM', '269', 0, []],
    ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'CD', '243', 0, []],
    ['Congo (Republic) (Congo-Brazzaville)', 'CG', '242', 0, []],
    ['Cook Islands', 'CK', '682', 0, []],
    ['Costa Rica', 'CR', '506', 0, []],
    ['Côte d’Ivoire', 'CI', '225', 0, []],
    ['Croatia (Hrvatska)', 'HR', '385', 0, []],
    ['Cuba', 'CU', '53', 0, []],
    ['Curaçao', 'CW', '599', 0, []],
    ['Cyprus (Κύπρος)', 'CY', '357', 0, []],
    ['Czech Republic (Česká republika)', 'CZ', '420', 0, []],
    ['Denmark (Danmark)', 'DK', '45', 0, []],
    ['Djibouti', 'DJ', '253', 0, []],
    ['Dominica', 'DM', '1', 13, ['767']],
    [
      'Dominican Republic (República Dominicana)',
      'DO',
      '1',
      2,
      ['809', '829', '849']
    ],
    ['Ecuador', 'EC', '593', 0, []],
    ['Egypt (‫مصر‬‎)', 'EG', '20', 0, []],
    ['El Salvador', 'SV', '503', 0, []],
    ['Equatorial Guinea (Guinea Ecuatorial)', 'GQ', '240', 0, []],
    ['Eritrea', 'ER', '291', 0, []],
    ['Estonia (Eesti)', 'EE', '372', 0, []],
    ['Ethiopia', 'ET', '251', 0, []],
    ['Falkland Islands (Islas Malvinas)', 'FK', '500', 0, []],
    ['Faroe Islands (Føroyar)', 'FO', '298', 0, []],
    ['Fiji', 'FJ', '679', 0, []],
    ['Finland (Suomi)', 'FI', '358', 0, []],
    ['France', 'FR', '33', 0, []],
    ['French Guiana (Guyane française)', 'GF', '594', 0, []],
    ['French Polynesia (Polynésie française)', 'PF', '689', 0, []],
    ['Gabon', 'GA', '241', 0, []],
    ['Gambia', 'GM', '220', 0, []],
    ['Georgia (საქართველო)', 'GE', '995', 0, []],
    ['Germany (Deutschland)', 'DE', '49', 0, []],
    ['Ghana (Gaana)', 'GH', '233', 0, []],
    ['Gibraltar', 'GI', '350', 0, []],
    ['Greece (Ελλάδα)', 'GR', '30', 0, []],
    ['Greenland (Kalaallit Nunaat)', 'GL', '299', 0, []],
    ['Grenada', 'GD', '1', 14, ['473']],
    ['Guadeloupe', 'GP', '590', 0, []],
    ['Guam', 'GU', '1', 15, ['671']],
    ['Guatemala', 'GT', '502', 0, []],
    ['Guernsey', 'GG', '44', 1, ['1481', '7781', '7839', '7911']],
    ['Guinea (Guinée)', 'GN', '224', 0, []],
    ['Guinea-Bissau (Guiné Bissau)', 'GW', '245', 0, []],
    ['Guyana', 'GY', '592', 0, []],
    ['Haiti', 'HT', '509', 0, []],
    ['Honduras', 'HN', '504', 0, []],
    ['Hong Kong (香港)', 'HK', '852', 0, []],
    ['Hungary (Magyarország)', 'HU', '36', 0, []],
    ['Iceland (Ísland)', 'IS', '354', 0, []],
    ['India (भारत)', 'IN', '91', 0, []],
    ['Indonesia', 'ID', '62', 0, []],
    ['Iran (‫ایران‬‎)', 'IR', '98', 0, []],
    ['Iraq (‫العراق‬‎)', 'IQ', '964', 0, []],
    ['Ireland', 'IE', '353', 0, []],
    ['Isle of Man', 'IM', '44', 2, ['1624', '74576', '7524', '7924', '7624']],
    ['Israel (‫ישראל‬‎)', 'IL', '972', 0, []],
    ['Italy (Italia)', 'IT', '39', 0, []],
    ['Jamaica', 'JM', '1', 4, ['876', '658']],
    ['Japan (日本)', 'JP', '81', 0, []],
    ['Jersey', 'JE', '44', 3, ['1534', '7509', '7700', '7797', '7829', '7937']],
    ['Jordan (‫الأردن‬‎)', 'JO', '962', 0, []],
    ['Kazakhstan (Казахстан)', 'KZ', '7', 1, ['33', '7']],
    ['Kenya', 'KE', '254', 0, []],
    ['Kiribati', 'KI', '686', 0, []],
    ['Kosovo', 'XK', '383', 0, []],
    ['Kuwait (‫الكويت‬‎)', 'KW', '965', 0, []],
    ['Kyrgyzstan (Кыргызстан)', 'KG', '996', 0, []],
    ['Laos (ລາວ)', 'LA', '856', 0, []],
    ['Latvia (Latvija)', 'LV', '371', 0, []],
    ['Lebanon (‫لبنان‬‎)', 'LB', '961', 0, []],
    ['Lesotho', 'LS', '266', 0, []],
    ['Liberia', 'LR', '231', 0, []],
    ['Libya (‫ليبيا‬‎)', 'LY', '218', 0, []],
    ['Liechtenstein', 'LI', '423', 0, []],
    ['Lithuania (Lietuva)', 'LT', '370', 0, []],
    ['Luxembourg', 'LU', '352', 0, []],
    ['Macau (澳門)', 'MO', '853', 0, []],
    ['Macedonia (FYROM) (Македонија)', 'MK', '389', 0, []],
    ['Madagascar (Madagasikara)', 'MG', '261', 0, []],
    ['Malawi', 'MW', '265', 0, []],
    ['Malaysia', 'MY', '60', 0, []],
    ['Maldives', 'MV', '960', 0, []],
    ['Mali', 'ML', '223', 0, []],
    ['Malta', 'MT', '356', 0, []],
    ['Marshall Islands', 'MH', '692', 0, []],
    ['Martinique', 'MQ', '596', 0, []],
    ['Mauritania (‫موريتانيا‬‎)', 'MR', '222', 0, []],
    ['Mauritius (Moris)', 'MU', '230', 0, []],
    ['Mayotte', 'YT', '262', 1, ['269', '639']],
    ['Mexico (México)', 'MX', '52', 0, []],
    ['Micronesia', 'FM', '691', 0, []],
    ['Moldova (Republica Moldova)', 'MD', '373', 0, []],
    ['Monaco', 'MC', '377', 0, []],
    ['Mongolia (Монгол)', 'MN', '976', 0, []],
    ['Montenegro (Crna Gora)', 'ME', '382', 0, []],
    ['Montserrat', 'MS', '1', 16, ['664']],
    ['Morocco (‫المغرب‬‎)', 'MA', '212', 0, []],
    ['Mozambique (Moçambique)', 'MZ', '258', 0, []],
    ['Myanmar (Burma) (မြန်မာ)', 'MM', '95', 0, []],
    ['Namibia (Namibië)', 'NA', '264', 0, []],
    ['Nauru', 'NR', '674', 0, []],
    ['Nepal (नेपाल)', 'NP', '977', 0, []],
    ['Netherlands (Nederland)', 'NL', '31', 0, []],
    ['New Caledonia (Nouvelle-Calédonie)', 'NC', '687', 0, []],
    ['New Zealand', 'NZ', '64', 0, []],
    ['Nicaragua', 'NI', '505', 0, []],
    ['Niger (Nijar)', 'NE', '227', 0, []],
    ['Nigeria', 'NG', '234', 0, []],
    ['Niue', 'NU', '683', 0, []],
    ['Norfolk Island', 'NF', '672', 0, []],
    ['North Korea (조선 민주주의 인민 공화국)', 'KP', '850', 0, []],
    ['Northern Mariana Islands', 'MP', '1', 17, ['670']],
    ['Norway (Norge)', 'NO', '47', 0, []],
    ['Oman (‫عُمان‬‎)', 'OM', '968', 0, []],
    ['Pakistan (‫پاکستان‬‎)', 'PK', '92', 0, []],
    ['Palau', 'PW', '680', 0, []],
    ['Palestine (‫فلسطين‬‎)', 'PS', '970', 0, []],
    ['Panama (Panamá)', 'PA', '507', 0, []],
    ['Papua New Guinea', 'PG', '675', 0, []],
    ['Paraguay', 'PY', '595', 0, []],
    ['Peru (Perú)', 'PE', '51', 0, []],
    ['Philippines', 'PH', '63', 0, []],
    ['Poland (Polska)', 'PL', '48', 0, []],
    ['Portugal', 'PT', '351', 0, []],
    ['Puerto Rico', 'PR', '1', 3, ['787', '939']],
    ['Qatar (‫قطر‬‎)', 'QA', '974', 0, []],
    ['Réunion (La Réunion)', 'RE', '262', 0, []],
    ['Romania (România)', 'RO', '40', 0, []],
    ['Russia (Россия)', 'RU', '7', 0, []],
    ['Rwanda', 'RW', '250', 0, []],
    ['Saint Barthélemy', 'BL', '590', 1, []],
    ['Saint Helena', 'SH', '290', 0, []],
    ['Saint Kitts and Nevis', 'KN', '1', 18, ['869']],
    ['Saint Lucia', 'LC', '1', 19, ['758']],
    ['Saint Martin (Saint-Martin (partie française))', 'MF', '590', 2, []],
    [
      'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
      'PM',
      '508',
      0,
      []
    ],
    ['Saint Vincent and the Grenadines', 'VC', '1', 20, ['784']],
    ['Samoa', 'WS', '685', 0, []],
    ['San Marino', 'SM', '378', 0, []],
    ['São Tomé and Príncipe (São Tomé e Príncipe)', 'ST', '239', 0, []],
    ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'SA', '966', 0, []],
    ['Senegal (Sénégal)', 'SN', '221', 0, []],
    ['Serbia (Србија)', 'RS', '381', 0, []],
    ['Seychelles', 'SC', '248', 0, []],
    ['Sierra Leone', 'SL', '232', 0, []],
    ['Singapore', 'SG', '65', 0, []],
    ['Sint Maarten', 'SX', '1', 21, ['721']],
    ['Slovakia (Slovensko)', 'SK', '421', 0, []],
    ['Slovenia (Slovenija)', 'SI', '386', 0, []],
    ['Solomon Islands', 'SB', '677', 0, []],
    ['Somalia (Soomaaliya)', 'SO', '252', 0, []],
    ['South Africa', 'ZA', '27', 0, []],
    ['South Korea (대한민국)', 'KR', '82', 0, []],
    ['South Sudan (‫جنوب السودان‬‎)', 'SS', '211', 0, []],
    ['Spain (España)', 'ES', '34', 0, []],
    ['Sri Lanka (ශ්‍රී ලංකාව)', 'LK', '94', 0, []],
    ['Sudan (‫السودان‬‎)', 'SD', '249', 0, []],
    ['Suriname', 'SR', '597', 0, []],
    ['Svalbard and Jan Mayen', 'SJ', '47', 1, ['79']],
    ['Swaziland', 'SZ', '268', 0, []],
    ['Sweden (Sverige)', 'SE', '46', 0, []],
    ['Switzerland (Schweiz)', 'CH', '41', 0, []],
    ['Syria (‫سوريا‬‎)', 'SY', '963', 0, []],
    ['Taiwan (台灣)', 'TW', '886', 0, []],
    ['Tajikistan', 'TJ', '992', 0, []],
    ['Tanzania', 'TZ', '255', 0, []],
    ['Thailand (ไทย)', 'TH', '66', 0, []],
    ['Timor-Leste', 'TL', '670', 0, []],
    ['Togo', 'TG', '228', 0, []],
    ['Tokelau', 'TK', '690', 0, []],
    ['Tonga', 'TO', '676', 0, []],
    ['Trinidad and Tobago', 'TT', '1', 22, ['868']],
    ['Tunisia (‫تونس‬‎)', 'TN', '216', 0, []],
    ['Turkey (Türkiye)', 'TR', '90', 0, []],
    ['Turkmenistan', 'TM', '993', 0, []],
    ['Turks and Caicos Islands', 'TC', '1', 23, ['649']],
    ['Tuvalu', 'TV', '688', 0, []],
    ['U.S. Virgin Islands', 'VI', '1', 24, ['340']],
    ['Uganda', 'UG', '256', 0, []],
    ['Ukraine (Україна)', 'UA', '380', 0, []],
    ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'AE', '971', 0, []],
    ['United Kingdom', 'GB', '44', 0, []],
    ['Uruguay', 'UY', '598', 0, []],
    ['Uzbekistan (Oʻzbekiston)', 'UZ', '998', 0, []],
    ['Vanuatu', 'VU', '678', 0, []],
    ['Vatican City (Città del Vaticano)', 'VA', '39', 1, ['06698']],
    ['Venezuela', 'VE', '58', 0, []],
    ['Vietnam (Việt Nam)', 'VN', '84', 0, []],
    ['Wallis and Futuna (Wallis-et-Futuna)', 'WF', '681', 0, []],
    ['Western Sahara (‫الصحراء الغربية‬‎)', 'EH', '212', 1, ['5288', '5289']],
    ['Yemen (‫اليمن‬‎)', 'YE', '967', 0, []],
    ['Zambia', 'ZM', '260', 0, []],
    ['Zimbabwe', 'ZW', '263', 0, []]
  ].map((x) => {
    return {
      name: x[0],
      iso2: x[1],
      dialCode: x[2],
      priority: x[3],
      areaCodes: x[4]
    };
  }) as CountryCode[]
).sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});

const resolveLookup = () => {
  const lookup: Record<string, CountryCode> = {};
  AllCountries.forEach((x) => {
    lookup[x.iso2] = x;
  });
  return lookup;
};

export const AllCountriesLookup = resolveLookup();

export default AllCountries;

export interface CountryCodeOption extends OptionModel {
  data: CountryCode;
}

export const FilterCountries = (iso2Codes: string[]) =>
  AllCountries.filter((x) => iso2Codes.includes(x.iso2));

export const AllCountryIso2Options: CountryCodeOption[] = AllCountries.map(
  (x) => ({
    label: x.name,
    value: x.iso2,
    data: x
  })
);

export const AllCountryNameOptions: CountryCodeOption[] = AllCountries.map(
  (x) => ({
    label: x.name,
    value: x.name,
    data: x
  })
);
