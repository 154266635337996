import { utcToZonedTime } from 'date-fns-tz';

import { utcDateOrUndefined } from '../_internal/utcDate';

export class ScheduleTime {
  timeZoneId?: string;
  utc?: Date;

  constructor(props?: Partial<ScheduleTime>) {
    props = props || {};
    Object.assign(this, props);
    this.utc = utcDateOrUndefined(props.utc);
  }

  tryGetZonedTime = (): Date | undefined => {
    if (!!this.utc && !!this.timeZoneId) {
      return utcToZonedTime(this.utc, this.timeZoneId);
    }

    return undefined;
  };
}
