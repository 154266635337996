import { toOptionModels } from '@komo-tech/core/models/OptionModel';
import {
  BooleanOperatorsArray,
  DateOperatorsArray,
  ExistsOperatorsArray,
  NumberOperatorsArray,
  OptionAllOperatorsArray,
  SingleStringOperators
} from '@komo-tech/core/models/predicate/PredicateOperators';
import { SchemaItemDataTypes } from '@komo-tech/core/models/SchemaDefinition';
import { PredicateFilterAfterOperatorFieldTypes } from '@komo-tech/ui/Form/PredicateFilter/types';

export enum FieldDataTypes {
  Unknown = 'Unknown',
  String = 'String',
  Number = 'Number',
  Date = 'Date',
  Boolean = 'Boolean'
}

export enum FieldTypes {
  Text = 'Text',
  Email = 'Email',
  Number = 'Number',
  Currency = 'Currency',
  Percentage = 'Percentage',
  Dropdown = 'Dropdown',
  MultipleSelect = 'MultipleSelect',
  Date = 'Date',
  DateTime = 'DateTime',
  Birthday = 'Birthday',
  Optin = 'Optin',
  Option = 'Option',
  Password = 'Password',
  Country = 'Country',
  TextArea = 'TextArea',
  /**
   * @deprecated use UniqueCode instead
   */
  Code = 'Code',
  UniqueCode = 'UniqueCode',
  Phone = 'Phone',
  File = 'File',
  BirthYear = 'BirthYear',
  Hidden = 'Hidden',
  Address = 'Address',
  Html = 'Html',
  QrCode = 'QrCode'
}

export const FieldTypesWithServerValidation = [
  FieldTypes.UniqueCode,
  FieldTypes.QrCode
];

export const getFieldDataType = (type: FieldTypes) => {
  switch (type) {
    case FieldTypes.Text:
    case FieldTypes.TextArea:
    case FieldTypes.Country:
    case FieldTypes.Phone:
    case FieldTypes.Email:
    case FieldTypes.Address:
    case FieldTypes.Option:
    case FieldTypes.Dropdown:
      return FieldDataTypes.String;
    case FieldTypes.Number:
    case FieldTypes.Currency:
    case FieldTypes.BirthYear:
    case FieldTypes.Percentage:
      return FieldDataTypes.Number;
    case FieldTypes.Date:
    case FieldTypes.DateTime:
    case FieldTypes.Birthday:
      return FieldDataTypes.Date;
    case FieldTypes.Optin:
      return FieldDataTypes.Boolean;
    default:
      return FieldDataTypes.Unknown;
  }
};

export const resolvePredicateFilterAfterOperatorFieldType = (
  type: FieldTypes
): PredicateFilterAfterOperatorFieldTypes => {
  switch (type) {
    case FieldTypes.Text:
    case FieldTypes.TextArea:
    case FieldTypes.Country:
    case FieldTypes.Phone:
    case FieldTypes.Email:
    case FieldTypes.Address:
      return 'text';
    case FieldTypes.Number:
    case FieldTypes.Currency:
    case FieldTypes.BirthYear:
    case FieldTypes.Percentage:
      return 'number';
    case FieldTypes.Date:
    case FieldTypes.DateTime:
    case FieldTypes.Birthday:
      return 'date';
    case FieldTypes.Option:
    case FieldTypes.MultipleSelect:
    case FieldTypes.Dropdown:
      return 'option';
    default:
      return 'none';
  }
};
export const resolveFieldPredicateOperators = (type: FieldTypes) => {
  switch (type) {
    case FieldTypes.Text:
    case FieldTypes.TextArea:
    case FieldTypes.Country:
    case FieldTypes.Phone:
    case FieldTypes.Email:
    case FieldTypes.Address:
      return toOptionModels(SingleStringOperators, true);
    case FieldTypes.Number:
    case FieldTypes.Currency:
    case FieldTypes.BirthYear:
    case FieldTypes.Percentage:
      return toOptionModels(NumberOperatorsArray, true);
    case FieldTypes.Date:
    case FieldTypes.DateTime:
    case FieldTypes.Birthday:
      return toOptionModels(DateOperatorsArray, true);
    case FieldTypes.Option:
    case FieldTypes.MultipleSelect:
    case FieldTypes.Dropdown:
      return toOptionModels(OptionAllOperatorsArray, true);
    case FieldTypes.Optin:
      return toOptionModels(BooleanOperatorsArray, true);
    case FieldTypes.Hidden:
      return toOptionModels(
        [...ExistsOperatorsArray, ...SingleStringOperators],
        true
      );
    default:
      return [];
  }
};

export const fieldToSchema = (type: FieldTypes) => {
  switch (type) {
    case FieldTypes.DateTime:
    case FieldTypes.Birthday:
      return SchemaItemDataTypes.Date;
    case FieldTypes.BirthYear:
    case FieldTypes.Number:
    case FieldTypes.Currency:
      return SchemaItemDataTypes.Numeric;
    default:
      return SchemaItemDataTypes.String;
  }
};
