import { Guid } from '@komo-tech/core/models/Guid';

export class FormFieldValue {
  id: Guid;
  value: string;

  constructor(props?: Partial<FormFieldValue>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
  }
}
