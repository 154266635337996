/**
 * Filter keys from the given object.
 * @param obj
 * @param predicate
 */
export const filterKeys = <T extends Record<string, any>>(
  obj: T,
  predicate: (key: keyof T) => boolean
) =>
  Object.keys(obj)
    .filter((key) => predicate(key))
    .reduce((prev, key) => Object.assign(prev, { [key]: obj[key] }), {});

/**
 * Filter private properties from the given object.
 * Private properties are any string keys that start with an underscore (_).
 * @param obj
 */
export const filterPrivateProperties = <T extends Record<string, any>>(
  obj: T
) =>
  filterKeys(obj, (key) => {
    if (typeof key !== 'string') {
      return true;
    }

    return !key.startsWith('_');
  });

export const createObjectFromJsonpath = (jsonpath: string, value?: unknown) => {
  const path = jsonpath.split('.');
  const obj = {};
  let current = obj;
  let parentKey;
  for (let i = 0; i < path.length; i++) {
    let key = path[i];
    // check if key is an array
    if (key.endsWith(']')) {
      parentKey = key.substring(0, key.indexOf('['));
      const index = parseInt(
        key.substring(key.indexOf('[') + 1, key.indexOf(']'))
      );
      current[parentKey] = current[parentKey] || [];
      for (let j = current[parentKey].length; j <= index; j++) {
        current[parentKey].push(null);
      }
      if (i === path.length - 1) {
        if (value === undefined) {
          value = parentKey + (index + 1);
        }
        current[parentKey][index] = value;
      } else {
        current[parentKey][index] = current[parentKey][index] || {};
        current = current[parentKey][index];
      }
      key = parentKey;
    } else if (i === path.length - 1) {
      if (value === undefined) {
        value = key;
      }
      current[key] = value;
    } else {
      current[key] = current[key] || {};
      current = current[key];
    }
  }
  return obj;
};
