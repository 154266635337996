export interface IHasOrder {
  order: number;
}

export const orderByAscending = (a: IHasOrder, b: IHasOrder): number =>
  a.order - b.order;

export const orderByDescending = (a: IHasOrder, b: IHasOrder): number =>
  b.order - a.order;

interface IMightHaveOrder {
  order?: number;
}
export const orderOptionalByAscending = (
  a: IMightHaveOrder,
  b: IMightHaveOrder
): number => {
  const aOrder = a.order === undefined ? 9999999 : a.order;
  const bOrder = b.order === undefined ? 9999999 : b.order;
  return aOrder - bOrder;
};
