import { isLocalhost } from '@komo-tech/core/utils/browser';

/**
 * Returns the URL of the site based off the domain, if its SSR or the domain is
 * localhost, then HTTP is used.
 * @param domain The domain to use if not localhost or SSR.
 */
export const getSiteUrl = (domain: string, noScheme?: boolean) => {
  const parts: string[] = [];
  if (!noScheme) {
    parts.push(`${window.location.protocol}/`);
  }
  if (isLocalhost() && domain?.toLowerCase() === 'localhost') {
    parts.push('localhost:3000');
  } else {
    parts.push(domain);
  }

  return parts.join('/');
};
