import isArray from 'lodash/isArray';
import lodashIsNil from 'lodash/isNil';
import lodashIsNull from 'lodash/isNull';
import lodashIsUndefined from 'lodash/isUndefined';

import { KeyOf } from '../models/path';

/**
 * Returns if the value is undefined.
 * @param value The value to test.
 */
export const isUndefined = lodashIsUndefined;

/**
 * Returns if the value is null.
 * @param value The value to test.
 */
export const isNull = lodashIsNull;

/**
 * Returns if the value is null or undefined.
 * @param value The value to test.
 */
export const isNil = lodashIsNil;

/**
 * Returns if the value is nil (null / undefined), empty string or whitespace.
 * @param value The value to test.
 * @returns True if nil, empty string or whitespace; else false.
 */
export const isNilOrWhiteSpace = (value?: string | unknown) =>
  isNil(value) || value.toString().trim().length === 0;

/**
 * Returns if the value is a string.
 * @param value The value to test.
 */
export const isString = (value: unknown) => typeof value === 'string';

/**
 * Returns if the value is a number.
 * @param value The value to test.
 */
export const isNumber = (value: unknown) => typeof value === 'number';

/**
 * Returns if the value is an object.
 * @param value The value to test.
 */
export const isObject = (value: unknown) =>
  !isNull(value) && typeof value === 'object';

export const isNotEmptyObject = (obj: any) => {
  return isObject(obj) && Object.keys(obj).length ? true : false;
};

/**
 * Returns if the specified type is a Date.
 * @param value The value to test.
 */
export const isDate = (value: unknown) => value instanceof Date;

/**
 * Typesafe function for referencing keys on an object
 * @param name key of generic.
 */
export function nameOf<T extends object>(name: KeyOf<T>) {
  return name;
}

export function classFromJson<T>(
  classObject: { new (partial?: any): T },
  json: string
) {
  if (!json) return undefined;
  try {
    return new classObject(JSON.parse(json));
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export function classArrayFromJsonOrFallback<T>(
  classObject: { new (partial?: any): T },
  json: string,
  fallback: T[]
) {
  if (!json) return fallback;
  try {
    const rawArray = JSON.parse(json);
    if (!isArray(rawArray)) {
      return fallback;
    }
    return (rawArray || []).map((x) => new classObject(x));
  } catch (error) {
    console.error(error);
    return fallback;
  }
}

export function classArrayFromJsonOrEmpty<T>(
  classObject: { new (partial?: any): T },
  json: string
) {
  return classArrayFromJsonOrFallback<T>(classObject, json, []);
}

export function classFromJsonOrFallback<T>(
  classObject: { new (partial?: any): T },
  json: string,
  fallback: T
) {
  if (!json) return fallback;
  try {
    return new classObject(JSON.parse(json));
  } catch (error) {
    console.error(error);
    return fallback;
  }
}

export function classFromJsonOrNew<T>(
  classObject: { new (partial?: any): T },
  json: string
) {
  return classFromJsonOrFallback(classObject, json, new classObject());
}

export function objectFromJson<T>(
  json: string,
  options?: {
    isValid: (obj: T) => boolean;
    fallback: T;
  }
) {
  if (!json) return options?.fallback;
  try {
    const obj = JSON.parse(json);
    if (options) {
      if (!options.isValid(obj)) {
        return options.fallback;
      }
    }
    return obj;
  } catch (error) {
    console.error(error);
    return options?.fallback;
  }
}

export function objectAssignWithoutKeys<T>(
  target: any,
  source: T,
  keys: (keyof T)[]
) {
  source = (source as any) || {};
  for (const i in source) {
    if ((keys as any as string[]).indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(source, i)) continue;
    target[i] = source[i];
  }
  return target;
}

export function toObjectLookup<T>(
  values: T[],
  keySelector: (value: T) => string,
  copyValueFn?: (value: T) => T
): Record<string, T> {
  return (values || []).reduce<Record<string, T>>((acc, x) => {
    acc[keySelector(x)] = !!copyValueFn ? copyValueFn(x) : x;
    return acc;
  }, {});
}
