export class SessionStorageHelper {
  public static polyfillData = {};

  public static sessionStorageEnabled(): boolean {
    const feature = 'feature:test';
    try {
      sessionStorage.setItem(feature, feature);
      sessionStorage.removeItem(feature);
      return true;
    } catch {
      return false;
    }
  }

  public static getItem(key: string): string | undefined {
    if (!key) {
      throw new Error('Key not specified');
    }
    try {
      if (SessionStorageHelper.sessionStorageEnabled()) {
        return sessionStorage.getItem(key);
      } else {
        return this.polyfillData[key];
      }
    } catch {}
    return undefined;
  }

  public static getOrCreateItem(
    key: string,
    valueFactory: (k: string) => string
  ): string | undefined {
    let value = SessionStorageHelper.getItem(key);
    if (value) {
      return value;
    }

    value = valueFactory(key);
    SessionStorageHelper.setItem(key, value);
    return value;
  }

  public static getItems(): Record<string, string> {
    try {
      if (SessionStorageHelper.sessionStorageEnabled()) {
        return { ...sessionStorage };
      } else {
        return { ...this.polyfillData };
      }
    } catch {}

    return {};
  }

  public static removeItem(key: string): void {
    if (!key) {
      throw new Error('Key not specified');
    }
    try {
      sessionStorage.removeItem(
        typeof key === 'string' ? key : (key as any).toString()
      );
    } catch {}
  }

  public static setItem(key: string, value: string): void {
    if (!key) {
      throw new Error('Key not specified');
    }
    try {
      if (this.sessionStorageEnabled()) {
        sessionStorage.setItem(key, value);
      } else {
        this.polyfillData[key] = value;
      }
    } catch {}
  }
}
