import isEmpty from 'lodash/isEmpty';

const CopyText = 'Copy';
const CopyDuplicateRegex = /(copy\s+copy)/i;
const EndsWithRegex = /(?:copy)?\s*-?\(?\s*(\d*)\s*\)?\s*$/i;
const EndsWithReplaceRegex = /((?:copy)?\s*-?\(?\s*\d*\s*\)?\s*)$/i;

/**
 * Creates a safe clone name.
 * @param name The name to clone.
 */
export const getCloneName = (name: string) => {
  if (isEmpty(name)) {
    return 'Copy';
  }

  while (CopyDuplicateRegex.test(name)) {
    name = name.replace(CopyDuplicateRegex, CopyText);
  }

  if (!EndsWithRegex.test(name)) {
    return name + ' 1';
  }

  let count = 2;
  const result = EndsWithRegex.exec(name);
  if (result.length == 2) {
    try {
      const parsed = parseInt(result['1'], 10);
      if (!isNaN(parsed)) {
        count = parsed + 1;
      }
    } catch {}
  }

  return `${name.replace(EndsWithReplaceRegex, '').trim()} ${count.toString()}`;
};

/**
 * Returns a clean version of the field name.
 * @param value The value to clean.
 * @return The clean version of the name.
 */
export const cleanFieldName = (value: string) =>
  (value || '')
    .replace(/([\s])/gi, '_')
    .replace(/([^a-zA-Z0-9_]+)/gi, '_')
    .replace(/([_]+)/gi, '_')
    .replace(/^([_]+)(.*)$/gi, '$2')
    .toLowerCase();
