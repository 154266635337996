import {
  FieldConfig,
  initTypedFieldConfig
} from '@/common/models/form/FormFieldConfig';
import { FieldTypes } from '@/common/models/form/FormFieldTypes';

export class ContactPropertyDefinitionProperties {
  Description: string;

  FieldConfigVersion?: string;
  FieldConfigJson?: string;

  constructor(props?: Partial<ContactPropertyDefinitionProperties>) {
    props = props || {};
    Object.assign(this, props);
  }

  getTypedFieldConfig(fieldType?: FieldTypes): FieldConfig {
    if (!fieldType) {
      return undefined;
    }

    return initTypedFieldConfig(fieldType, this.FieldConfigJson);
  }
}
