import { IHasSiteCardProperties } from '../models/SiteCardProperties';

export interface GetPrimaryColorProps {
  overrideColor?: string;
  card?: IHasSiteCardProperties;
  properties?: { AccentBackgroundColor?: string; AccentTextColor?: string };
  fallback?: string;
}

export const getPrimaryBackgroundColor = ({
  overrideColor,
  card,
  properties,
  fallback = '#000000'
}: GetPrimaryColorProps): string => {
  return (
    overrideColor ||
    card?.properties?.ButtonBackgroundColor ||
    properties?.AccentBackgroundColor ||
    fallback
  );
};

export const getPrimaryTextColor = ({
  overrideColor,
  card,
  properties,
  fallback = '#ffffff'
}: GetPrimaryColorProps): string => {
  return (
    overrideColor ||
    card?.properties?.ButtonTextColor ||
    properties?.AccentTextColor ||
    fallback
  );
};
