export interface DistributionLifeCycleEvent {
  label: string;
  value: string;
  entityId?: string;
  currentEventTime?: Date;
}

export const CountdownLifeCycleEvents = [
  { label: 'Countdown Ended', value: 'Countdown::EndedAt' }
];
export const TriviaGameLifeCycleEvents = [
  { label: 'Game Start', value: 'TriviaGame::StartedAt' },
  { label: 'Game End', value: 'TriviaGame::EndedAt' }
];

const CouponIssuedEventName = 'Coupon::IssuedAt';
const CouponExpiredEventName = 'Coupon::ExpiredAt';
const CouponRedeemedEventName = 'Coupon::RedeemedAt';

export const StaticCouponLifecycleEvents = [
  { label: 'Coupon Issued (and unused)', value: CouponIssuedEventName },
  { label: 'Unused Coupon Expiry', value: CouponExpiredEventName },
  { label: 'Coupon Redeemed', value: CouponRedeemedEventName }
];

export const AllLifeCycleEvents = [
  ...CountdownLifeCycleEvents,
  ...TriviaGameLifeCycleEvents,
  ...StaticCouponLifecycleEvents
];

export const getCouponReminderLifeCycleEvents = (couponOffer: {
  expiredAt?: Date;
}): DistributionLifeCycleEvent[] => {
  return [
    {
      label: 'Coupon Issued (and unused)',
      value: CouponIssuedEventName
    },
    {
      label: 'Unused Coupon Expiry',
      value: CouponExpiredEventName,
      currentEventTime: couponOffer.expiredAt
    }
    // ,
    // {
    //   label: 'Coupon Redeemed',
    //   value: CouponRedeemedEventName
    // }
  ];
};
