import { OptionModel } from '../OptionModel';

type Subset<K, T extends K> = T;

const NumberOperatorsConst = [
  'Equals',
  'DoesNotEqual',
  'IsLessThan',
  'IsLessThanOrEquals',
  'IsGreaterThan',
  'IsGreaterThanOrEquals'
] as const;

export type NumberOperators = (typeof NumberOperatorsConst)[number];
export const NumberOperatorsArray =
  NumberOperatorsConst as unknown as NumberOperators[];

const StringOperatorsConst = [
  'Is',
  'Contains',
  'StartsWith',
  'EndsWith',
  'IsNot',
  'DoesNotContain',
  'RegexMatch',
  'IsOneOf',
  'ContainsOneOf',
  'IsNotOneOf',
  'DoesNotContainOneOf'
] as const;

export type StringOperators = (typeof StringOperatorsConst)[number];
export const StringOperatorsArray =
  StringOperatorsConst as unknown as StringOperators[];

export type SingleStringOperatorTypes = Subset<
  StringOperators,
  | 'Is'
  | 'Contains'
  | 'StartsWith'
  | 'EndsWith'
  | 'IsNot'
  | 'DoesNotContain'
  | 'RegexMatch'
>;

/**
 * String operators that compare against a single string
 */
export const SingleStringOperators: SingleStringOperatorTypes[] = [
  'Is',
  'Contains',
  'StartsWith',
  'EndsWith',
  'IsNot',
  'DoesNotContain',
  'RegexMatch'
];

export type StringListOperatorTypes = Subset<
  StringOperators,
  'IsOneOf' | 'ContainsOneOf' | 'IsNotOneOf' | 'DoesNotContainOneOf'
>;

/**
 * String operators that compare against a list of strings
 */
export const StringListOperators: StringListOperatorTypes[] = [
  'IsOneOf',
  'ContainsOneOf',
  'IsNotOneOf',
  'DoesNotContainOneOf'
];

const DateAgoOperatorsConst = ['LessThan', 'MoreThan'] as const;
type DateAgoOperators = (typeof DateAgoOperatorsConst)[number];

const DateBasicOperatorsConst = ['IsBefore', 'IsAfter', 'IsBetween'] as const;
export type DateBasicOperators = (typeof DateBasicOperatorsConst)[number];

export const DateAgoOperatorsArray =
  DateAgoOperatorsConst as unknown as DateAgoOperators[];
export const DateBasicOperatorsArray =
  DateBasicOperatorsConst as unknown as DateBasicOperators[];

export type DateOperators = DateBasicOperators | DateAgoOperators;
export const DateOperatorsArray = [
  ...DateBasicOperatorsArray,
  ...DateAgoOperatorsArray
];

const BooleanOperatorsConst = ['IsTrue', 'IsFalse'] as const;

export type BooleanOperators = (typeof BooleanOperatorsConst)[number];
export const BooleanOperatorsArray =
  BooleanOperatorsConst as unknown as BooleanOperators[];

const ExistsOperatorsConst = ['IsSet', 'IsNotSet'] as const;

export type ExistsOperators = (typeof ExistsOperatorsConst)[number];
export const ExistsOperatorsArray =
  ExistsOperatorsConst as unknown as ExistsOperators[];

const OptionSingleOperatorsConst = ['Is', 'IsNot'] as const;

export type OptionSingleOperators = (typeof OptionSingleOperatorsConst)[number];
export const OptionSingleOperatorsArray =
  OptionSingleOperatorsConst as unknown as OptionSingleOperators[];

const OptionListOperatorsConst = ['IsOneOf', 'IsNotOneOf'] as const;

export type OptionListOperators = (typeof OptionListOperatorsConst)[number];
export const OptionListOperatorsArray =
  OptionListOperatorsConst as unknown as OptionListOperators[];

export const OptionAllOperatorsArray = [
  ...OptionSingleOperatorsArray,
  ...OptionListOperatorsArray
];

const MultiOptionSingleOperatorsConst = ['Has', 'DoesNotHave'] as const;

export type MultiOptionSingleOperators =
  (typeof MultiOptionSingleOperatorsConst)[number];
export const MultiOptionSingleOperatorsArray =
  MultiOptionSingleOperatorsConst as unknown as MultiOptionSingleOperators[];

const MultiOptionListOperatorsConst = [
  'HasAnyOf',
  'HasNoneOf',
  'HasAllOf',
  'DoesNotHaveAllOf'
] as const;

export type MultiOptionListOperators =
  (typeof MultiOptionListOperatorsConst)[number];
export const MultiOptionListOperatorsArray =
  MultiOptionListOperatorsConst as unknown as MultiOptionListOperators[];

const MultiOptionNumberOperatorsConst = [
  'HasExactly',
  'HasMoreThan',
  'HasFewerThan'
] as const;

export type MultiOptionNumberOperators =
  (typeof MultiOptionNumberOperatorsConst)[number];
export const MultiOptionNumberOperatorsArray =
  MultiOptionNumberOperatorsConst as unknown as MultiOptionNumberOperators[];

export type MultiOptionAllOperators =
  | MultiOptionSingleOperators
  | MultiOptionListOperators
  | MultiOptionNumberOperators;
export const MultiOptionAllOperatorsArray = [
  ...MultiOptionSingleOperatorsArray,
  ...MultiOptionListOperatorsArray,
  ...MultiOptionNumberOperatorsArray
];

export const isExistsOperator = (operator: string) =>
  ExistsOperatorsArray.some((o) => o === operator);

export const isStringListOperator = (
  operator: string
): operator is StringListOperatorTypes =>
  StringListOperators.some((x) => x === operator);

export const isDateAgoOperator = (
  operator: string
): operator is DateAgoOperators =>
  DateAgoOperatorsArray.some((o) => o === operator);

export const DateAgoUnitOptions: OptionModel[] = [
  { label: 'minutes', value: 'm' },
  { label: 'hours', value: 'h' },
  { label: 'days', value: 'd' },
  { label: 'weeks', value: 'w' },
  { label: 'months', value: 'mo' },
  { label: 'years', value: 'y' }
];

export const isDateOperator = (operator: string) =>
  DateOperatorsArray.some((o) => o === operator);

export const isNumberOperator = (operator: string) =>
  NumberOperatorsArray.some((o) => o === operator);

export const isBooleanOperator = (operator: string) =>
  BooleanOperatorsArray.some((o) => o === operator);

export const isOptionSingleOperator = (operator: string) =>
  [...OptionSingleOperatorsArray, ...MultiOptionSingleOperatorsArray].some(
    (o) => o === operator
  );
export const isOptionListOperator = (operator: string) =>
  [...OptionListOperatorsArray, ...MultiOptionListOperatorsArray].some(
    (o) => o === operator
  );

export const isOptionNumberOperator = (operator: string) =>
  MultiOptionNumberOperatorsArray.some((o) => o === operator);

export const isOptionMultiSelectOperator = (operator: string) =>
  isOptionListOperator(operator) || isOptionNumberOperator(operator);
