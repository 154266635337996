import { immerable } from 'immer';

export class CommunicationSubscriptionProperties {
  [immerable] = true;

  ConsentFieldLabel?: string;
  ConsentFieldLabelEditorStateJson?: string;

  constructor(props?: Partial<CommunicationSubscriptionProperties>) {
    props = props || {};
    Object.assign(this, props);
  }
}
