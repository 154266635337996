import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

export class FormFieldContactPropertyOptionProperties {
  ImageJson: string;
  /**
   * @deprecated was named this for a small while but should use CapitalCasing
   */
  imageJson?: string;

  constructor(props?: Partial<FormFieldContactPropertyOptionProperties>) {
    props = props || {};
    Object.assign(this, props);

    if (!this.ImageJson && !!this.imageJson) {
      this.ImageJson = this.imageJson;
    }
  }

  get image() {
    return ImageDataModel.fromJsonOrUrl(this.ImageJson);
  }
}
