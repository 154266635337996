import { ScheduleTime } from '@komo-tech/core/models/ScheduleTime';

export class PublishingSchedule {
  publishAt: ScheduleTime;
  unPublishAt: ScheduleTime;

  constructor(props?: Partial<PublishingSchedule>) {
    props = props || {};
    Object.assign(this, props);
    this.publishAt = new ScheduleTime(props.publishAt);
    this.unPublishAt = new ScheduleTime(props.unPublishAt);
  }

  get timeZoneId(): string | undefined {
    return this.publishAt.timeZoneId ?? this.unPublishAt.timeZoneId;
  }
}
