import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { SchemaDefinition } from '@komo-tech/core/models/SchemaDefinition';
import { mapArray } from '@komo-tech/core/utils/array';
import { utcDateOrNow } from '@komo-tech/core/utils/date';
import { cleanFieldName } from '@komo-tech/core/utils/names';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { FrontReceiptValidatorDefinition } from '@/common/models/receipt-validator-definition/Front/FrontReceiptValidatorDefinition';
import { SiteCard } from '@/common/models/SiteCard';

import { FormField } from './FormField';
import { FormProperties } from './FormProperties';

export class Form {
  id: Guid;
  companyId: Guid;
  name = '';
  fields: FormField[] = [];
  updatedAt: Date;
  properties: FormProperties;
  referenceTableId?: Guid;
  receiptValidatorDefinitionId?: Guid;

  /** Front only*/
  frontReceiptValidatorDefinition?: FrontReceiptValidatorDefinition;

  get activeFields() {
    return (this.fields || []).filter((x) => x.isActive);
  }

  get hasEmail() {
    return (this.fields || []).some((x) => x.isEmailField);
  }

  constructor(props?: Partial<Form>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.companyId = Guid.valueOrEmpty(props.companyId);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.fields = FormField.toOrderedArray(
      mapArray(props.fields, (x) => new FormField(x))
    );
    this.properties = new FormProperties(props.properties);
    this.referenceTableId = Guid.valueOrUndefined(props.referenceTableId);
    this.receiptValidatorDefinitionId = Guid.valueOrUndefined(
      props.receiptValidatorDefinitionId
    );
    this.frontReceiptValidatorDefinition = props.frontReceiptValidatorDefinition
      ? new FrontReceiptValidatorDefinition(
          props.frontReceiptValidatorDefinition
        )
      : undefined;
  }

  public getHeaderProperty(fallback?: string) {
    return this.properties.Header || fallback || '';
  }

  public getSubHeaderProperty(fallback?: string) {
    return this.properties.SubHeader || fallback || '';
  }

  getFormColors(options?: { withTransparentBg?: boolean }) {
    return {
      '--dynamic-form-background': options?.withTransparentBg
        ? 'transparent'
        : this.properties.BackgroundColor,
      '--dynamic-form-color': this.properties.TextColor
    };
  }

  getFieldsForForm(sessionValues: Record<string, string> = {}) {
    const fields = this.fields.reduce<{
      emailField?: FormField;
      fields: FormField[];
      fieldsWithoutEmail: FormField[];
    }>(
      (acc, f) => {
        if (!f.isActive) return acc;

        if (
          (f.properties.IsHiddenIfHasValue ||
            f.isCommunicationSubscriptionField) &&
          sessionValues[f.name]
        )
          return acc;

        acc.fields.push(f);
        if (f.isEmailField) {
          acc.emailField = f;
        } else {
          acc.fieldsWithoutEmail.push(f);
        }
        return acc;
      },
      { emailField: undefined, fields: [], fieldsWithoutEmail: [] }
    );
    return {
      emailField: fields.emailField,
      fields: fields.fields.sort(orderByAscending),
      fieldsWithoutEmail: fields.fieldsWithoutEmail.sort(orderByAscending)
    };
  }

  public getColors(options?: {
    card?: SiteCard;
    properties?: SitePageProperties;
  }) {
    const formButtonBackground = this.properties.ButtonBackgroundColor;
    const formButtonText = this.properties.ButtonTextColor;
    const siteAccentBackground = options?.properties?.AccentBackgroundColor;
    const siteAccentText = options?.properties?.AccentTextColor;
    const cardButtonBackground =
      options?.card?.properties?.ButtonBackgroundColor;
    const cardButtonText = options?.card?.properties?.ButtonTextColor;

    return {
      buttonBackgroundColor:
        formButtonBackground || siteAccentBackground || '#000000',
      buttonTextColor: formButtonText || siteAccentText || '#FFFFFF',
      successBackgroundColor:
        cardButtonBackground || siteAccentBackground || '#000000',
      successTextColor: cardButtonText || siteAccentText || '#FFFFFF'
    };
  }

  public addField(field: Partial<FormField>) {
    if (!this.fields) this.fields = [];
    this.fields.push(
      new FormField({ ...field, order: this.fields.length + 1 })
    );
  }

  public getCoverImageUploadPath(siteId: Guid) {
    return `/site/${siteId}/form/${this.id}/cover-image`;
  }

  public getFormField(id: Guid) {
    return this.fields.find((x) => x.id.equals(id));
  }

  toSchema(name?: string) {
    return new SchemaDefinition({
      id: this.id.toString(),
      name: cleanFieldName(name || this.name),
      items: this.fields.map((f) => f.toSchemaItem())
    });
  }
}
