import { AllCountryIso2Options } from '@komo-tech/core/data/countries';
import { Guid } from '@komo-tech/core/models/Guid';
import { IHasId } from '@komo-tech/core/models/IHasId';
import { IHasOrder, orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { OptionModel } from '@komo-tech/core/models/OptionModel';
import { KeyOf } from '@komo-tech/core/models/path';
import {
  SchemaItemDataTypes,
  SchemaItemDefinition
} from '@komo-tech/core/models/SchemaDefinition';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { FormFileAllowedType } from '@komo-tech/core/utils/file';
import { cleanFieldName } from '@komo-tech/core/utils/names';
import { asNumber } from '@komo-tech/core/utils/number';
import { SessionStorageHelper } from '@komo-tech/core/utils/sessionStorage';
import { isNilOrWhiteSpace } from '@komo-tech/core/utils/type';
import isNil from 'lodash/isNil';

import { toFormFileAllowedType } from '@/common/models/AssetTypes';
import { AdminCommunicationSubscription } from '@/common/models/communications/CommunicationSubscription/AdminCommunicationSubscription';
import { FormFieldCommunicationSubscription } from '@/common/models/communications/CommunicationSubscription/FormFieldCommunicationSubscription';
import { AdminContactPropertyDefinition } from '@/common/models/contacts/Admin/AdminContactPropertyDefinition';
import { FormFieldContactPropertyOption } from '@/common/models/contacts/shared/FormFieldContactPropertyOption';
import { FormFieldPropertyDefinition } from '@/common/models/contacts/shared/FormFieldPropertyDefinition';

import {
  DropdownFieldConfig,
  FieldConfig,
  HtmlFieldConfig,
  OptinFieldConfig
} from './FormFieldConfig';
import { FormFieldOption } from './FormFieldOption';
import { FormFieldProperties } from './FormFieldProperties';
import { FieldTypes } from './FormFieldTypes';
import { FormFieldValue } from './FormFieldValue';

export const ExcludedFieldTypes = [FieldTypes.Html];

export class FormField implements IHasOrder, IHasId<Guid> {
  id: Guid;
  formId: Guid;
  type: FieldTypes = FieldTypes.Text;
  name: string;
  label: string;
  order: number;
  defaultValue?: any;
  value: string;
  isRequired: boolean = false;
  isHidden: boolean = false;
  isActive: boolean = true;
  minNumber?: number;
  maxNumber?: number;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  options?: FormFieldOption[];
  defaultOptin?: boolean;
  properties: FormFieldProperties;
  values: FormFieldValue[];
  dataListId?: Guid;

  referenceTableId?: Guid;

  get hasReferenceTable() {
    return !!this.referenceTableId;
  }

  get isHiddenField() {
    return this.isHidden || this.type == FieldTypes.Hidden;
  }

  propertyDefinitionId?: Guid;
  propertyDefinition?: FormFieldPropertyDefinition;

  communicationSubscriptionId?: Guid;
  communicationSubscription?: FormFieldCommunicationSubscription;

  constructor(props?: Partial<FormField>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.values = mapArray(props.values, (x) => new FormFieldValue(x));
    this.properties = new FormFieldProperties(props.properties);
    this.dataListId = Guid.valueOrUndefined(props.dataListId);

    this.referenceTableId = Guid.valueOrUndefined(props.referenceTableId);
    this.propertyDefinitionId = Guid.valueOrUndefined(
      props.propertyDefinitionId
    );
    this.propertyDefinition = props.propertyDefinition
      ? new FormFieldPropertyDefinition(props.propertyDefinition)
      : undefined;
    this.communicationSubscriptionId = Guid.valueOrUndefined(
      props.communicationSubscriptionId
    );
    this.communicationSubscription = props.communicationSubscription
      ? new FormFieldCommunicationSubscription(props.communicationSubscription)
      : undefined;

    this.options = mapArray(props.options, (x) => new FormFieldOption(x));

    if (!this.options.length && !this.propertyDefinitionId) {
      switch (props.type) {
        case FieldTypes.Option:
        case FieldTypes.Dropdown:
          this.options = [
            new FormFieldOption({
              id: Guid.newGuid(),
              order: 1
            }),
            new FormFieldOption({
              id: Guid.newGuid(),
              order: 2
            })
          ];
      }
    }
  }

  get isEmailField() {
    return this.name.toLowerCase() === 'email';
  }

  get isEntityPropertyField() {
    return !!this.propertyDefinitionId;
  }

  get isCommunicationSubscriptionField() {
    return !!this.communicationSubscriptionId;
  }

  get isSubscriptionOrPropertyField() {
    return this.isEntityPropertyField || this.isCommunicationSubscriptionField;
  }

  get propertyFieldPlaceholder() {
    if (!this.isEntityPropertyField) return '';

    // todo - option-based placeholder
    return this.propertyDefinition?.placeholder || '';
  }

  get displayPlaceholder() {
    return this.propertyFieldPlaceholder || this.properties.placeholder;
  }

  get propertyFieldLabel() {
    if (!this.isEntityPropertyField) return '';

    return this.propertyDefinition?.label || '';
  }

  get displayLabel() {
    if (this.type == FieldTypes.Optin) {
      const text = this.getConfigProperty<OptinFieldConfig>('Text');
      if (!!text) return text;
      if (this.isCommunicationSubscriptionField)
        return this.communicationSubscription.consentFieldLabel;
    }

    if (this.type == FieldTypes.Html) {
      const html = this.getConfigProperty<HtmlFieldConfig>('Html');
      if (!!html) return html;
    }

    const label = this.label || this.propertyFieldLabel;
    return (label || '').replace('&amp;', '&');
  }

  get displayOptions(): (FormFieldOption | FormFieldContactPropertyOption)[] {
    return (
      (this.isEntityPropertyField
        ? this.propertyDefinition.displayOptions
        : this.options) || []
    );
  }

  getSanitisedDisplayOptions() {
    return (this.displayOptions || []).sort(orderByAscending).map((o) => ({
      ...o,
      value: o.id.toString(),
      label: isNilOrWhiteSpace(o.label) ? o.value : o.label
    }));
  }

  getConfigProperty<T extends FieldConfig = FieldConfig>(name: KeyOf<T>) {
    const v = this.getTypedFieldConfig()?.[name as string];
    return !isNil(v) ? v : this.getPropertyTypedFieldConfig()?.[name as string];
  }

  getNullableConfigProperty<T extends FieldConfig = FieldConfig>(
    name: KeyOf<T>
  ) {
    const typeField = this.getTypedFieldConfig()?.[name as string];
    if (typeField !== undefined) return typeField;

    const propertyField = this.getPropertyTypedFieldConfig()?.[name as string];
    if (propertyField !== undefined) return propertyField;

    return undefined;
  }

  get propertyFieldWordLimit() {
    if (!this.isEntityPropertyField) return undefined;

    return this.propertyDefinition.wordLimit;
  }

  get wordLimit() {
    if (!this.isEntityPropertyField) return this.properties.WordLimit;

    return isNil(this.properties.WordLimit)
      ? this.propertyDefinition?.wordLimit
      : this.properties.WordLimit;
  }

  /**
   * Todo: migrate all field-specific config to FieldConfigs.
   * Currently only PhoneFieldConfig.AllowedCountries is pulled from here.
   */
  getTypedFieldConfig() {
    return this.properties.getTypedFieldConfig(this.type);
  }

  getPropertyTypedFieldConfig() {
    if (!this.isEntityPropertyField) return this.getTypedFieldConfig();
    return this.propertyDefinition?.getTypedFieldConfig();
  }

  getFormFileAllowedTypes() {
    const allowedTypes = this.properties.getAllowedFileFields();
    if (!allowedTypes.length) {
      return [] as FormFileAllowedType[];
    }

    return allowedTypes
      .filter(toFormFileAllowedType)
      .map(toFormFileAllowedType);
  }

  getOptions(): OptionModel<string>[] {
    switch (this.type) {
      case FieldTypes.Country:
        return AllCountryIso2Options;
      case FieldTypes.BirthYear:
        const max = new Date().getFullYear();
        const min = max - 100;
        const years: OptionModel[] = [];
        for (let i = max; i >= min; i--) {
          years.push({ label: i.toString(), value: i.toString() });
        }
        return years;
      default:
        return this.displayOptions.map((x) => ({
          value: x.value,
          label: x.label
        }));
    }
  }

  shouldShowWithAuth(
    requiredFields: string[],
    options?: { excludeEmail?: boolean }
  ) {
    if (!this.isActive) {
      return false;
    }

    if (!requiredFields.some((x) => x === this.name, this)) {
      return false;
    }

    if (options?.excludeEmail && this.isEmailField) {
      return false;
    }

    return true;
  }

  getInternalName() {
    return `${this.displayLabel} (${this.name})`;
  }

  toSchemaItem() {
    return new SchemaItemDefinition({
      id: this.id.toString(),
      name: this.name,
      dataType: FormField.resolveSchemaDataType(this.type),
      isRequired: false
    });
  }

  getDefaultValue() {
    const sessionStorageValue = SessionStorageHelper.getItem(this.name);
    switch (this.type) {
      case FieldTypes.Country:
        if (sessionStorageValue) {
          this.defaultValue = this.getOptions()?.find(
            (x) => x.label === sessionStorageValue
          )?.label;
        }
        return this.defaultValue;
      case FieldTypes.BirthYear:
        if (sessionStorageValue) {
          this.defaultValue = this.getOptions()?.find(
            (x) => x.value === sessionStorageValue
          )?.value;
        }
        return this.defaultValue;
      case FieldTypes.Dropdown:
      case FieldTypes.Option:
        if (sessionStorageValue) {
          this.defaultValue = this.options
            ?.find((x) => x.value === sessionStorageValue)
            ?.id?.toString?.();
        }
        if (Guid.isValid(this.defaultValue)) {
          return this.defaultValue;
        }
        if (
          this.getConfigProperty<DropdownFieldConfig>('DefaultsTo') == 'Option'
        ) {
          const defOption =
            this.getConfigProperty<DropdownFieldConfig>('DefaultOption');
          if (Guid.isValid(defOption)) {
            return defOption?.toString?.();
          }
        }
        break;
      case FieldTypes.Optin:
        if (sessionStorageValue) {
          this.defaultOptin = asBoolean(sessionStorageValue, false);
        }
        return (
          this.getConfigProperty<OptinFieldConfig>('DefaultValue') ??
          this.defaultOptin ??
          false
        );
      case FieldTypes.Number:
        if (sessionStorageValue) {
          this.defaultValue = asNumber(sessionStorageValue, undefined);
        }
        return this.defaultValue;
      default:
        if (sessionStorageValue) {
          this.defaultValue = sessionStorageValue;
        }
        return this.defaultValue ?? '';
    }
    return undefined;
  }

  static resolveSchemaDataType(type: FieldTypes) {
    switch (type) {
      case FieldTypes.Email:
      case FieldTypes.Currency:
      case FieldTypes.Percentage:
      case FieldTypes.Dropdown:
      case FieldTypes.MultipleSelect:
      case FieldTypes.Html:
      case FieldTypes.Option:
      case FieldTypes.Password:
      case FieldTypes.Country:
      case FieldTypes.TextArea:
      case FieldTypes.Code:
      case FieldTypes.UniqueCode:
      case FieldTypes.QrCode:
      case FieldTypes.Phone:
      case FieldTypes.Hidden:
      case FieldTypes.Address:
      case FieldTypes.File:
      case FieldTypes.BirthYear:
      case FieldTypes.Text:
        return SchemaItemDataTypes.String;

      case FieldTypes.Number:
        return SchemaItemDataTypes.Numeric;

      case FieldTypes.Optin:
        return SchemaItemDataTypes.Boolean;

      case FieldTypes.Date:
      case FieldTypes.DateTime:
      case FieldTypes.Birthday:
        return SchemaItemDataTypes.Date;
      default:
        // noinspection UnnecessaryLocalVariableJS
        const unsupported: never = type;
        console.error('Unsupported FieldType', unsupported);

        return SchemaItemDataTypes.String;
    }
  }

  static toOrderedArray(value: FormField[]) {
    if (!value) return [];
    return mapArray(value, (x) => new FormField(x)).sort(orderByAscending);
  }

  static newFromOption(option: OptionModel<FieldTypes>) {
    return new FormField({
      id: Guid.newGuid(),
      isRequired: option.value !== FieldTypes.Html,
      type: option.value,
      label: option.label,
      name: cleanFieldName(option.label.replaceAll('(', '').replaceAll(')', ''))
    });
  }

  static newFromProperty(property: AdminContactPropertyDefinition) {
    return new FormField({
      id: Guid.newGuid(),
      isRequired: property.fieldType !== FieldTypes.Html,
      type: property.fieldType,
      name: property.name,
      propertyDefinitionId: property.id,
      propertyDefinition: new FormFieldPropertyDefinition(property)
    });
  }

  static newFromSubscription(subscription: AdminCommunicationSubscription) {
    return new FormField({
      id: Guid.newGuid(),
      type: FieldTypes.Optin,
      name: subscription.uniqueId,
      communicationSubscriptionId: subscription.id,
      communicationSubscription: subscription
    });
  }
}

export class FormFieldAggregateCollection {
  aggregates: FormFieldAggregate[];

  constructor(props?: Partial<FormFieldAggregateCollection>) {
    props = props || {};
    this.aggregates = mapArray(
      props.aggregates,
      (x) => new FormFieldAggregate(x)
    );
  }

  addField(field: FormField) {
    const index = this.aggregates.findIndex((x) => x.name === field.name);
    if (index < 0) {
      this.aggregates.push(
        new FormFieldAggregate({ name: field.name, fields: [field] })
      );
    } else {
      this.aggregates[index].fields.push(field);
    }
  }
}

export class FormFieldAggregate {
  name: string;
  fields: FormField[];

  get field(): FormField {
    return this.fields?.[0];
  }

  get label(): string {
    return this.field?.displayLabel;
  }

  constructor(props?: Partial<FormFieldAggregate>) {
    props = props || {};
    Object.assign(this, props);
    this.fields = mapArray(props.fields, (x) => new FormField(x));
  }
}
